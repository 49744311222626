export enum BW_OPT_1 {
  BW_OPT_SHOW_OWNER         = "0",
  BW_OPT_SHOW_USERS_NAMES   = "1",
  BW_OPT_SHOW_USERS_AVATARS = "2",
  BW_OPT_HIDE_USER_FIELD    = "10",
}

export enum BW_OPT_2 {
  BW_OPT_SHOW_CREATION_DATE = "0",
  BW_OPT_SHOW_DEADLINE_DATE = "1",
  BW_OPT_HIDE_DATE_FIELD    = "10",
}

export enum BW_OPT_TAGS {
  HIDDEN = "hidden",
  LEFT = "left",
  RIGHT = "right",
  AFTER = "after",
}
