
import {defineComponent, ref} from "vue";
import { useIntersectionObserver } from "@vueuse/core";

import {onIdle} from "./idle.js";

export default defineComponent({
  name: "the-lazy",
  props: {
    forceRender: Boolean,
    renderOnIdle: Boolean,
    unrender: Boolean,
    minHeight: Number,
    unrenderDelay: {
      type: Number,
      default: 10000,
    },
  },

  setup(props) {
    const shouldRender = ref(props.forceRender);
    const targetEl = ref();
    const fixedMinHeight = ref(0);
    let unrenderTimer;
    let renderTimer;

    const { stop } = useIntersectionObserver(
      targetEl,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          clearTimeout(unrenderTimer);
          renderTimer = setTimeout(
            () => (shouldRender.value = true),
            props.unrender ? 100 : 0
          );
          shouldRender.value = true;
          if (!props.unrender) {
            stop();
          }
        } else if (props.unrender) {
          clearTimeout(renderTimer);
          unrenderTimer = setTimeout(() => {
            fixedMinHeight.value = targetEl.value.clientHeight;
            shouldRender.value = false;
          }, props.unrenderDelay);
        }
      },
      {
        rootMargin: "600px",
      });

    if (props.renderOnIdle) {
      onIdle(() => {
        shouldRender.value = true;
        if (!props.unrender) {
          stop();
        }
      });
    }
    return {
       targetEl, shouldRender, fixedMinHeight
    }
  }
});
