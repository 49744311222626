

import {
  computed,
  defineAsyncComponent,
  defineComponent, inject, nextTick,
  onBeforeUnmount,
  onMounted, onUpdated, ref, watch
} from "vue";
import { useStore } from "vuex";
import {useI18n} from 'vue-i18n'
import TheStep from "@/components/TheStep/TheStep.vue";
import "tippy.js/dist/tippy.css";
//import { useContextMenu } from "@/components/TheBoard/useContextMenu";
import { useScroll } from "@vueuse/core";
//import TheQuickAdd from "@/components/TheQuickAdd";

import board_service from "@/services/board_service";
import { useFloatingExists } from "@/composables/useFloatingExists"
//import EmptyBoardPanel from "./EmptyBoardPanel.vue";
import MenuTicket from "@/components/Menus/MenuTicket.vue"
import {IData} from "@/types"
import {useRouter} from "vue-router"
import {useTicketActions} from "@/composables/useTicketActions"
import { autorefreshBlockAdd, autorefreshBlockRemove, autorefreshScreen } from "@/composables/useFetchState"
import {useAppUtils} from "@/composables/useAppUtils"
import TicketService from "@/services/ticket_service"
//import TheDevPanel from "@/components/TheBoard/TheDevPanel.vue"
import {useHighlight} from "@/composables/useHighlight"
import {useScrollKeep} from "@/composables/useScrollKeep"

const EmptyBoardPanel = defineAsyncComponent(() => import('@/components/TheBoard/EmptyBoardPanel.vue'))

export default defineComponent({
  name: "TheBoard",
  props: {
    projectId: String,
    canAddTicket: {
      type: Boolean,
      default: true,
    }
  },
  emits: [
    'load'
  ],
  components: {
    //TheQuickAdd,
    TheStep,
    EmptyBoardPanel,
    MenuTicket,
    //TheDevPanel
},

  computed: {
  },

  setup() {
    console.log('setup TheBoard: ');
    const store = useStore();
    const router = useRouter()
    const { t } = useI18n()
    // const projectId = store.getters['board/project'];
    const steps = computed(() => store.getters['board/steps']);

    const ticketsCount = computed(() => store.getters['board/getTicketsCount']);
    const isBigBoard = computed(() => ticketsCount.value > 1000 );

    // const isDebugMode = !!inject('isDebugMode')
    // const isShowDevTools = computed(() => isDebugMode && store.state.dev.isShowDevTools)

    const isLazyStepOn = computed(() => isBigBoard.value ? '1' : '0')
    //const isLazyStepOn = computed(() => { return store.getters["settings/lazy_on"] == '2' ? isBigBoard.value ? '1' : '0' : store.getters["settings/lazy_on"] });
    // const isLazyOn = computed(() => store.getters['settings/lazy_on']);
    const ticketViewSettings = computed(() => store.getters['settings/ticketView']);

    const stepsTicketsCount = computed(() => store.getters['board/getRealTicketsCountInStep']);

    const element = ref<HTMLElement | null>(null);
    const { x, y } = useScroll(element);
    const {
      keepScroll,
      restoreScroll,
    } = useScrollKeep()

    const expandedSteps = computed(() => steps.value.filter(step => step.expanded))
    const quickAddStepId = computed(() => expandedSteps.value[0]?.id)

    const emptyBoardType = computed(()=>store.getters['board/getTemplateType']);

    const { highlightTicketOnBoard } = useHighlight()


    //const quickAddStepId = ref(undefined);
    //const quickAddTaskName = ref("");

    //const isOpenQuickAdd = ref(false)
    //const refPopupQuickAddTo = ref();

    //const { isExist: isExistQuickAdd, triggerHandler: triggerHandlerQuickAdd } = useFloatingExists(refPopupQuickAddTo)

    const expandStep = async ({ id, isExpanded }) => {
      if (isExpanded || expandedSteps.value.length > 1) {
        const blockType = 'Board_expandStep'
        const blockAttrs = {id}
        autorefreshBlockAdd(blockType, blockAttrs)
        await store.dispatch('board/toggleStepExpanded', {stepId: id, isExpanded})
        await store.dispatch('board/clearAnimatedTickets')
        autorefreshBlockRemove(blockType, blockAttrs)

      }
    };

    // const onQuickAddHandler = async (event) => {
    //   await triggerHandlerQuickAdd()
    //   await nextTick()
    //   refPopupQuickAddTo.value.refPositionTarget = event.refBtn.value;
    //   refPopupQuickAddTo.value.focusInputField();
    // }

    const refBoard = ref();
    onMounted(async () => {
      console.log('mount TheBoard');
      restoreScroll(element, 'board')
    })

    onBeforeUnmount(() => {
      console.log('onBeforeUnmount TheBoard');
      keepScroll(element, 'board')
    })

    onUpdated(() => {
      console.log('Board updated');
    })

    let dragElement;
    const isDragging = ref(false);
    const onStartDrag = function ({ step, event }) {
      store.dispatch('board/clearAnimatedTickets');
      dragElement = { oldStep: step, oldPos: event.oldIndex };
      isDragging.value = true;
      console.log('Dragg on');
    }

    const onOrderUpdate = async function ({ step, element, pos }) {
      //debugger
      const ticketId = element.id
      const ticketElement = document.querySelector(`.tickets-wrap .ticket[data-id="${ticketId}"]`)
      let prevTicketElement:  HTMLElement | null  = ticketElement?.previousElementSibling as HTMLElement
      let nextTicketElement:  HTMLElement | null = ticketElement?.nextElementSibling as HTMLElement
      const oldPos = dragElement?.oldPos
      const oldStep = dragElement?.oldStep?.id
      dragElement = null
      if (!(oldPos !== undefined && oldStep)) {
        return
      }
      const stepObj = steps.value.find(item => item.id == step.id)
      console.log('onOrderUpdate', step, stepObj, stepObj.expanded)
      if (stepObj && !stepObj.expanded) {
        store.commit('board/add_TicketIdToStep', { stepId: step.id, id: ticketId })
        pos = 0
        prevTicketElement = null
        nextTicketElement = null
      } else {
        highlightTicketOnBoard(ticketId)
      }

      try {
        const result = await board_service.move_ticket(ticketId, step.id, pos, prevTicketElement?.dataset?.id, nextTicketElement?.dataset?.id)
        console.log('TheBoard move_ticket result', result)
        store.dispatch('updateTicketGlobally', {
          id: ticketId,
          attrs: {
            ...result.xdata?.ticket,
            team: result.xdata?.ticket.users || []
          }})
        //await store.dispatch('board/update_ticketTeam', {id: ticketId, team: result.xdata?.ticket.users || []})

        await showResponseSuccessMessage(result)
      } catch (error) {
        console.log('TheBoard move_ticket error', error)
        if(error !== 'cancel') {
          store.commit("board/remove_TicketIdFromStep", { stepId: step.id, id: ticketId });
          store.commit("board/add_TicketIdToStep", { stepId: oldStep, id: ticketId, pos: oldPos });
        }
      }

      // board_service.move_ticket(ticketId, step.id, pos, prevTicketElement?.dataset?.id, nextTicketElement?.dataset?.id).catch((error) => {
      //   if(error !== 'cancel') {
      //     store.commit("board/remove_TicketIdFromStep", { stepId: step.id, id: ticketId });
      //     store.commit("board/add_TicketIdToStep", { stepId: oldStep, id: ticketId, pos: oldPos });
      //   }
      // })

    }

    const onEndDrag = function () {
      dragElement = null;
      isDragging.value = false;
      console.log('Dragg off');
    }

/*
    store.watch((state, getters) => getters.project, (newValue) => {
      // console.log('change project ');
      // store.commit('board/setProject', newValue.id);
      // store.dispatch('board/fetchBoard');
    })
*/

    const clearTickets = function () {
      store.dispatch('board/clearTickets');
    }

    const bg = computed(() => store.getters['settings/show_avatar_background']);
    const toggleAvatarBackground = function () {
      store.commit('settings/setShowAvatarBackground', { show_avatar_background: bg.value == "1" ? "0" : '1' });
    }

    // const toggleLazy = function () {
    //   let i = parseInt(isLazyOn.value, 10);
    //   i++;
    //   i = i % 3;
    //   store.commit('settings/setLazyOn', { lazy_on: i.toString() });
    // }

/*
    const fetchTickets = function () {
      store.dispatch('board/fetchBoard');
    }
*/

    //useContextMenu({});

    const refTicketMenuButton = ref()
    const ticketMenuParams = ref<IData>({})
    const refTicketMenu = ref<InstanceType<typeof MenuTicket> | null>(null)
    const {isExist: isExistTicketMenu, triggerHandler: triggerHandlerTicketMenu} = useFloatingExists(refTicketMenu)

    // const emitter: any = inject('emitter')

    watch(() => refTicketMenu.value?.isOpened, (newValue, oldValue) => {
      console.log('TheBoard watch refTicketMenu.value?.isOpened', newValue, oldValue)
      // if (newValue) {
      //   emitter.emit('BoardMenuTicketOpen')
      // }
      if (!newValue && oldValue !== undefined) {
        if (ticketMenuParams.value?.id) {
          //store.commit(moduleName + '/updateTicket', {id: ticketMenuParams.value.id, attrs: {isActiveMenu: false}})
          store.commit('board/setActiveTicketMenuById', {id: ticketMenuParams.value.id, isActiveMenu: false})
        }
        ticketMenuParams.value = {}
      }
    })

    const ticketMenuHandler = async ({event, item}) => {
      const shouldTriggerMenu = item.id != ticketMenuParams.value?.id

      if (ticketMenuParams.value?.id) {
        store.commit('board/setActiveTicketMenuById', {id: ticketMenuParams.value.id, isActiveMenu: false})
      }
      refTicketMenuButton.value = event.target.closest('button')
      await nextTick()


      console.log('TheBoard ticketMenuHandler', shouldTriggerMenu, item.id, ticketMenuParams.value.id, refTicketMenu.value?.isOpened, ticketMenuParams.value, item)

      ticketMenuParams.value = {
        id: item.id,
        name: item.title_orig || item.title,
        color: item.color,
        archived: !!item.archived,
        readonly: !item.isEdit || item.isEdit === '0',
      }

      store.commit('board/setActiveTicketMenuById', {id: ticketMenuParams.value.id, isActiveMenu: true})

      if (refTicketMenu.value?.isOpened) {
        refTicketMenu.value.isOpened = false
      }
      if (shouldTriggerMenu) {
        await triggerHandlerTicketMenu()
      }
    }

    const {
      cloneTicket,
      archiveTicket,
      removeTicket,
      assignTicketToMe,
      moveTicketToNextStage,
    } = useTicketActions()

    const {
      isSuccessServerResponse,
      showResponseSuccessMessage,
    } = useAppUtils()

    const ticketMenuActionHandler = async (action, ticketId, data: any = {}) => {
      console.log('TheBoard ticketMenuActionHandler', action, ticketId, data)
      refTicketMenu.value.isOpened = false
      switch (action) {
        case 'view':
          await router.push({name: 'Ticket', params: {id: ticketId}})
          break
        case 'movefirst':
          autorefreshBlockAdd('Board_ticketMenuAction_movefirst')
          store.commit('board/setProgress', {ticketId, value:true})
          board_service.move_ticket_first(ticketId).then(()=>{
            store.commit('board/moveTopTicketById', {id: ticketId})
          }).finally(()=>{
            store.commit('board/setProgress', {ticketId, value:false})
          })
          autorefreshBlockRemove('Board_ticketMenuAction_movefirst')
          break
        case 'moveend':
          autorefreshBlockAdd('Board_ticketMenuAction_moveend')
          store.commit('board/setProgress', {ticketId, value:true})
          board_service.move_ticket_first(ticketId, true).then(()=>{
            store.commit('board/moveBottomTicketById', {id: ticketId})
          }).finally(()=>{
            store.commit('board/setProgress', {ticketId, value:false})
          })
          autorefreshBlockRemove('Board_ticketMenuAction_moveend')
          break
        case 'movenext':
          autorefreshBlockAdd('Board_ticketMenuAction_movenext')
          store.commit('board/setProgress', {ticketId, value:true})
          try {
            const result = await moveTicketToNextStage(ticketId, 'board')
            console.log('ticketMenuActionHandler movenext success', result)
            await showResponseSuccessMessage(result)
            if (isSuccessServerResponse(result) && result.xdata?.step) {
              // store.commit(moduleName + '/updateTicket', {id: ticketId, attrs: {
              //     flow_step_id: result.xdata.step.id,
              //     stepName: result.xdata.step.name,
              //   }})
              //await store.dispatch('updateTicketGlobally', {id: ticketId, attrs: {...result.xdata.ticket}})
              await store.dispatch('updateTicketGlobally', {id: ticketId, attrs: {
                  ...result.xdata.ticket,
                  flow_step_id: result.xdata.step.id,
                  stepName: result.xdata.step.name,
                  team: result.xdata?.ticket?.users || []
                }})
              //await store.dispatch('board/update_ticketTeam', {id: ticketId, team: result.xdata?.ticket?.users || []})
              store.commit('board/moveNextStepTicketById', {id: ticketId})

            }
          } catch (e) {
            console.log('ticketMenuActionHandler movenext error', e)
          }
          store.commit('board/setProgress', {ticketId, value:false})
          autorefreshBlockRemove('Board_ticketMenuAction_movenext')
          break
        case 'assignme':
          autorefreshBlockAdd('Board_ticketMenuAction_assignme')
          store.commit('board/setProgress', {ticketId, value:true})
          try {
            const result = await assignTicketToMe(ticketId, 'board')
            console.log('ticketMenuActionHandler assignme success', result)
            await showResponseSuccessMessage(result)
            if (isSuccessServerResponse(result)) {
              //store.commit(moduleName + '/updateTicket', {id: ticketId, attrs: {team: result.xdata.team || []}})
              await store.dispatch('board/update_ticketTeam', {id: ticketId, team: result.xdata.team || []})
            }
          } catch (e) {
            console.log('ticketMenuActionHandler assignme error', e)
          }
          store.commit('board/setProgress', {ticketId, value:false})
          autorefreshBlockRemove('Board_ticketMenuAction_assignme')
          break
        case 'archive':
        case 'restore':
          autorefreshBlockAdd('Board_ticketMenuAction_archive')
          store.commit('board/setProgress', {ticketId, value:true})
          try {
            const result = await archiveTicket(ticketId)
            console.log('ticketMenuActionHandler archive/restore success', result)
            await showResponseSuccessMessage(result)
            if (isSuccessServerResponse(result)) {
              store.dispatch("board/removeTicket", ticketId).then(()=>{
                store.commit("board/setArchivedCount", (store.getters['board/getArchivedCount']+1));
              })
            }
          } catch (e) {
            console.log('ticketMenuActionHandler archive/restore error', e)
          }
          store.commit('board/setProgress', {ticketId, value:false})
          autorefreshBlockRemove('Board_ticketMenuAction_archive')
          break
        case 'clone':
          await cloneTicket(ticketId)
          break
        case 'delete':
          autorefreshBlockAdd('Board_ticketMenuAction_delete')
          try {
            const result = await removeTicket(ticketId, data.name)
            console.log('ticketMenuActionHandler delete success', result)
            await showResponseSuccessMessage(result)
            if (isSuccessServerResponse(result)) {
              // store.commit(moduleName + '/removeTicket', ticketId)
              store.commit('board/deleteTicketById', {id: ticketId});
              if (!ticketsCount.value) {
                await autorefreshScreen()
              }
            }
          } catch (e) {
            console.log('ticketMenuActionHandler delete error', e)
          }
          autorefreshBlockRemove('Board_ticketMenuAction_delete')
          break
        case 'movedToProject':
          console.log('ListView movedToProject', ticketId, data)
          //store.commit(moduleName + '/removeTicket', ticketId)
          store.commit('board/deleteTicketById', {id: ticketId})
          await nextTick()
          await autorefreshScreen()
          break
      }
    }

    const previewTicketColor = (id, color) => {
      // console.log('TheBoard previewTicketColor', id, color)
      store.commit("board/setTicketTmpColorById", {id, tmpColor: color || color == 0 ? color.toString() : null})
    }

    const revertTicketColor = (id) => {
      previewTicketColor(id, null)
    }


    const changeTicketColor = async (id, color) => {
      console.log('TheBoard changeTicketColor', id, color)
      autorefreshBlockAdd('Board_ticketMenuAction_color')
      store.commit('board/setProgress', {ticketId:id, value:true})
      refTicketMenu.value.isOpened = false
      await nextTick()
      previewTicketColor(id, color)
      try {
        const result = await TicketService.setTicketColor(id, color)
        console.log('TheBoard changeTicketColor success', result)
        store.commit("board/setTicketColorById", {id: id, color: color});
        await showResponseSuccessMessage(result)
      } catch (e) {
        console.log('TheBoard changeTicketColor error', e)
      }
      store.commit('board/setProgress', {ticketId:id, value:false})
      autorefreshBlockRemove('Board_ticketMenuAction_color')
    }

    return {
      t,
      steps,
      expandStep,
      clearTickets,
      toggleAvatarBackground,
      // toggleLazy,
      ticketViewSettings,
      onStartDrag,
      onOrderUpdate,
      onEndDrag,
      // lastTicketsUID: computed(() => {
      //   return store.getters['board/lastTicketUID']
      // }),
      stepsTicketsCount,
      // isLazyOn,
      isLazyStepOn,
      isBigBoard,
      //fetchTickets,
      ticketsCount,
      element,
      x, y,
      quickAddStepId,
      //quickAddTaskName,
      //refQuickAddTo,
      //refPopupQuickAddTo,
      //isExistQuickAdd,
      //isOpenQuickAdd,
      //getQuickAddTriggerTargets,
      //onQuickAddHandler,
      //hidePopupQuickAdd
      refBoard,
      isDragging,
      refTicketMenuButton,
      refTicketMenu,
      ticketMenuParams,
      isExistTicketMenu,
      ticketMenuHandler,
      ticketMenuActionHandler,
      previewTicketColor,
      changeTicketColor,
      revertTicketColor,
      EmptyBoardPanel,
      emptyBoardType,
      //isShowDevTools,
    }
  }
})

