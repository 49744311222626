import {nextTick} from "vue";

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onIdle(cb = () => {}) {
  if ("requestIdleCallback" in window) {
    window.requestIdleCallback(cb);
  } else {
    setTimeout(() => {
      nextTick(cb);
    }, 300);
  }
}
