import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "board-view-screen flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tickets_toolbar = _resolveComponent("tickets-toolbar")!
  const _component_TheBoard = _resolveComponent("TheBoard")!
  const _component_menu_screen_board = _resolveComponent("menu-screen-board")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_tickets_toolbar, {
      screen: "board",
      "can-add-ticket": _ctx.canAddTicket,
      onMenuTrigger: _ctx.toolbarTriggerMenu
    }, null, 8, ["can-add-ticket", "onMenuTrigger"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TheBoard, {
        ref: "board",
        "project-id": _ctx.projectId,
        "can-add-ticket": _ctx.canAddTicket
      }, null, 8, ["project-id", "can-add-ticket"])
    ]),
    (_ctx.isExistScreenMenu)
      ? (_openBlock(), _createBlock(_component_menu_screen_board, {
          key: 0,
          ref: "refScreenMenu",
          "position-target": _ctx.refScreenMenuTarget,
          "show-value": _ctx.screenMenuValueShow,
          "rearrange-value": _ctx.screenMenuValueRearrange,
          onAction: _ctx.screenMenuActionHandler
        }, null, 8, ["position-target", "show-value", "rearrange-value", "onAction"]))
      : _createCommentVNode("", true)
  ], 64))
}