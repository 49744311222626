import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "targetEl",
    class: _normalizeClass({'ticket-empty': !_ctx.shouldRender}),
    style: _normalizeStyle(`min-height:${_ctx.fixedMinHeight ? _ctx.fixedMinHeight : _ctx.minHeight}px`)
  }, [
    (_ctx.shouldRender)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true)
  ], 6))
}