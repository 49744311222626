import { ref } from "vue";

export const useElementHover = () => {

    const isShow = ref(false);

    let hoveredEl = null;
    //let showDelay = 1000;
    //let singletoneDelay = 1000;

    function show() {
        isShow.value = true;
    }

    function hide() {
        isShow.value = false;
    }

    function enter({id, elem}) {
        console.log('enter', id, elem);
        if (hoveredEl!=elem) {
            hoveredEl = elem;
            show();
        }
    }

    function leave({id, elem}) {
        console.log('leave', id, elem);
        hide();
        hoveredEl = null;
    }

    return {
        enter,
        leave,
        isShow,
        hoveredEl
    }
}
